'use client';

import type {FC} from 'react';
import {useLiveQuery} from 'next-sanity/preview';

import {FullPageSpinner} from 'components/full-page-spinner';

import {HeaderClient} from './client';
import type {ResolvedMainNavigationQueryResult} from './query';
import {resolvedMainNavigationQuery} from './query';

export const HeaderDraft: FC = () => {
	const [mainNavigation, loading] = useLiveQuery<ResolvedMainNavigationQueryResult[] | null>(
		null,
		resolvedMainNavigationQuery,
	);

	if (loading) {
		return <FullPageSpinner />;
	}

	if (!mainNavigation) {
		return null;
	}

	return <HeaderClient mainNavigation={mainNavigation} />;
};
