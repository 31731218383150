export const externalLinkToHref = (href?: string) => href ?? '#';

export type LinkableDocumentTypes = 'page' | 'frontpage';

export const internalLinkToHref = (documentType?: LinkableDocumentTypes, slug?: string) => {
	if (!documentType) {
		return '#';
	}

	switch (documentType) {
		case 'page':
			slug = `/${slug}`;
			break;
		default:
			slug = '/';
			break;
	}

	return slug;
};

export const checkIfLinkShouldUseAnchorTag = (href?: string) => {
	if (!href) return false;

	return (
		href?.startsWith('https://') ||
		href?.startsWith('http://') ||
		href?.startsWith('www.') ||
		href?.startsWith('mailto:') ||
		href?.startsWith('tel:')
	);
};

/**
 * Splits a string into groups of 2 characters and joins them with a space
 * Gets rid of all non-digit characters, in case there are already spaces in the string
 * @param value - The string to split
 * @returns The string with spaces between the groups
 * @example
 * phoneNumber('1234567') // 12 34 56 7
 * phoneNumber('123 4567') // 12 34 56 7
 */
export const formatPhoneNumber = (value: string) => {
	const groups = value.replace(/\D/g, '').match(/.{1,2}/g);
	return groups?.join(' ') ?? '';
};

export function assertValue<T>(v: T | undefined, errorMessage: string): T {
	if (v === undefined) {
		throw new Error(errorMessage);
	}

	return v;
}
