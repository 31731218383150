'use client';

import {Fragment, useEffect, useState} from 'react';
import {usePopper} from 'react-popper';
import {Dialog, Disclosure, Popover, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import {usePathname} from 'next/navigation';
import Logo from 'public/logo.svg';
import {internalLinkToHref} from 'utils';

import type {ResolvedMainNavigationQueryResult} from './query';

interface Props {
	mainNavigation?: ResolvedMainNavigationQueryResult[];
}

export const HeaderClient = ({mainNavigation}: Props) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>();
	const [popperElement, setPopperElement] = useState<HTMLElement | null>();
	const {styles, attributes} = usePopper(referenceElement, popperElement);
	const pathname = usePathname();

	useEffect(() => {
		setMobileMenuOpen(false);
	}, [pathname]);

	return (
		<header className={clsx('bg-primary-dark', 'shadow-sm')}>
			<nav
				className={clsx(
					'mx-auto',
					'flex',
					'w-full',
					'max-w-7xl',
					'items-center',
					'justify-between',
					'p-6',
					'lg:px-8',
					'gap-4',
				)}
				aria-label="Global"
			>
				<div className={clsx('flex', 'lg:flex-1')}>
					<Link href="/" className={clsx('-m-1.5', 'px-1.5')}>
						<span className={clsx('sr-only')}>Forside</span>
						<Logo className={clsx('max-h-10', 'w-full')} />
					</Link>
				</div>
				<div className={clsx('flex', 'lg:hidden')}>
					<button
						type="button"
						className={clsx(
							'-m-2.5',
							'inline-flex',
							'items-center',
							'justify-center',
							'rounded-md',
							'p-2.5',
							'text-white',
						)}
						onClick={() => setMobileMenuOpen(true)}
					>
						<span className={clsx('sr-only')}>Open main menu</span>
						<Bars3Icon className={clsx('h-6', 'w-6')} aria-hidden="true" />
					</button>
				</div>
				<div className={clsx('hidden', 'lg:flex', 'lg:gap-x-12')}>
					{mainNavigation?.map((link) => {
						if (!link) {
							return null;
						}

						if (link._type === 'dropdown') {
							return (
								<Popover key={link.title} className={clsx('relative')}>
									{({open}) => (
										<>
											<Popover.Button
												ref={setReferenceElement}
												className={clsx(
													'flex',
													'items-center',
													'gap-x-1',
													'text-sm',
													'font-semibold',
													'leading-6',
													'text-white',
													'hover:text-secondary',
													'focus:text-secondary',
												)}
											>
												{link.title}
												<ChevronDownIcon
													className={clsx(
														'h-5',
														'w-5',
														'flex-none',
														'text-gray-400',
														open ? 'rotate-180' : '',
													)}
													aria-hidden="true"
												/>
											</Popover.Button>

											<Transition
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel
													ref={setPopperElement}
													style={styles.popper}
													{...attributes.popper}
													className={clsx(
														'z-10',
														'mt-3',
														'min-w-[150px]',
														'rounded-3xl',
														'bg-white',
														'shadow-lg',
														'ring-1',
														'ring-gray-900/5',
													)}
												>
													<div className={clsx('p-4')}>
														{link.dropdownLinks?.map((subLink) => (
															<div
																key={subLink._key}
																className={clsx(
																	'group',
																	'relative',
																	'flex',
																	'items-center',
																	'gap-x-6',
																	'rounded-lg',
																	'p-4',
																	'text-sm',
																	'leading-6',
																	'hover:bg-gray-50',
																)}
															>
																<div className={clsx('flex-auto')}>
																	<>
																		{subLink.type ===
																		'internal' ? (
																			<Popover.Button
																				as={Link}
																				href={internalLinkToHref(
																					subLink
																						.reference
																						?._type,
																					subLink
																						.reference
																						?.slug
																						?.current,
																				)}
																				className={clsx(
																					'block',
																					'font-semibold',
																					'text-gray-900',
																					'whitespace-nowrap',
																				)}
																			>
																				{subLink.title}
																				<span
																					className={clsx(
																						'absolute',
																						'inset-0',
																					)}
																				/>
																			</Popover.Button>
																		) : (
																			<Popover.Button
																				as={Link}
																				href={
																					subLink.href ??
																					'#'
																				}
																				className={clsx(
																					'block',
																					'font-semibold',
																					'text-gray-900',
																					'whitespace-nowrap',
																				)}
																				target={
																					subLink.targetBlank
																						? '_blank'
																						: '_self'
																				}
																			>
																				{subLink.title}
																				<span
																					className={clsx(
																						'absolute',
																						'inset-0',
																					)}
																				/>
																			</Popover.Button>
																		)}
																	</>
																</div>
															</div>
														))}
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>
							);
						}
						if (link._type === 'navigationLink') {
							if (link.type === 'internal') {
								return (
									<Link
										key={link.title}
										href={internalLinkToHref(
											link.reference?._type,
											link.reference?.slug?.current,
										)}
										className={clsx(
											'text-sm',
											'font-semibold',
											'leading-6',
											'text-white',
											'hover:text-secondary',
											'focus:text-secondary',
										)}
									>
										{link.title}
									</Link>
								);
							} else {
								return (
									<Link
										key={link.title}
										href={link.href ?? '#'}
										className={clsx(
											'text-sm',
											'font-semibold',
											'leading-6',
											'text-white',
											'hover:text-secondary',
											'focus:text-secondary',
										)}
										target={link.targetBlank ? '_blank' : '_self'}
									>
										{link.title}
									</Link>
								);
							}
						}
					})}
				</div>
			</nav>

			<Dialog
				as="div"
				className={clsx('lg:hidden')}
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
			>
				<div className={clsx('fixed', 'inset-0', 'z-10')} />
				<Dialog.Panel
					className={clsx(
						'fixed',
						'inset-y-0',
						'right-0',
						'z-10',
						'w-full',
						'overflow-y-auto',
						'bg-white',
						'p-6',
						'sm:max-w-sm',
						'sm:ring-1',
						'sm:ring-gray-900/10',
					)}
				>
					<div className={clsx('flex', 'items-center', 'justify-between')}>
						<Link href="/" className={clsx('-m-1.5', 'p-1.5')}>
							<span className={clsx('sr-only')}>Your Company</span>
							<Logo className={clsx('h-8', 'w-auto')} />
						</Link>
						<button
							type="button"
							className={clsx('-m-2.5', 'rounded-md', 'p-2.5', 'text-gray-700')}
							onClick={() => setMobileMenuOpen(false)}
						>
							<span className={clsx('sr-only')}>Close menu</span>
							<XMarkIcon className={clsx('h-6', 'w-6')} aria-hidden="true" />
						</button>
					</div>
					<div className={clsx('mt-6', 'flow-root')}>
						<div className={clsx('-my-6', 'divide-y', 'divide-gray-500/10')}>
							<div className={clsx('space-y-2', 'py-6')}>
								{mainNavigation?.map((link) => {
									if (!link) {
										return null;
									}

									if (link._type === 'dropdown') {
										return (
											<Disclosure
												key={link.title}
												as="div"
												className={clsx('-mx-3')}
											>
												{({open}) => (
													<>
														<Disclosure.Button
															className={clsx(
																'flex',
																'w-full',
																'items-center',
																'justify-between',
																'rounded-lg',
																'py-2',
																'pl-3',
																'pr-3.5',
																'text-base',
																'font-semibold',
																'leading-7',
																'text-gray-900',
																'hover:bg-gray-50',
															)}
														>
															{link.title}
															<ChevronDownIcon
																className={clsx(
																	open ? 'rotate-180' : '',
																	'size-5 flex-none',
																)}
																aria-hidden="true"
															/>
														</Disclosure.Button>
														<Disclosure.Panel
															className={clsx('mt-2', 'space-y-2')}
														>
															{link.dropdownLinks?.map((subLink) => {
																if (subLink.type === 'internal') {
																	return (
																		<Disclosure.Button
																			key={subLink.title}
																			as={Link}
																			href={internalLinkToHref(
																				subLink.reference
																					?._type,
																				subLink.reference
																					?.slug?.current,
																			)}
																			className={clsx(
																				'block',
																				'rounded-lg',
																				'py-2',
																				'pl-6',
																				'pr-3',
																				'text-sm',
																				'font-semibold',
																				'leading-7',
																				'text-gray-900',
																				'hover:bg-gray-50',
																			)}
																		>
																			{subLink.title}
																		</Disclosure.Button>
																	);
																}
																if (subLink.type === 'external') {
																	return (
																		<Disclosure.Button
																			key={subLink.title}
																			as={Link}
																			href={
																				subLink.href ?? '#'
																			}
																			target={
																				subLink.targetBlank
																					? '_blank'
																					: '_self'
																			}
																			className={clsx(
																				'block',
																				'rounded-lg',
																				'py-2',
																				'pl-6',
																				'pr-3',
																				'text-sm',
																				'font-semibold',
																				'leading-7',
																				'text-gray-900',
																				'hover:bg-gray-50',
																			)}
																		>
																			{subLink.title}
																		</Disclosure.Button>
																	);
																}
															})}
														</Disclosure.Panel>
													</>
												)}
											</Disclosure>
										);
									}

									if (link._type === 'navigationLink') {
										if (link.type === 'internal') {
											return (
												<Link
													key={link.title}
													href={internalLinkToHref(
														link.reference?._type,
														link.reference?.slug?.current,
													)}
													target={link.targetBlank ? '_blank' : '_self'}
													className={clsx(
														'-mx-3',
														'block',
														'rounded-lg',
														'px-3',
														'py-2',
														'text-base',
														'font-semibold',
														'leading-7',
														'text-gray-900',
														'hover:bg-gray-50',
													)}
												>
													{link.title}
												</Link>
											);
										} else {
											return (
												<Link
													key={link.title}
													href={link.href ?? '#'}
													target={link.targetBlank ? '_blank' : '_self'}
													className={clsx(
														'-mx-3',
														'block',
														'rounded-lg',
														'px-3',
														'py-2',
														'text-base',
														'font-semibold',
														'leading-7',
														'text-gray-900',
														'hover:bg-gray-50',
													)}
												>
													{link.title}
												</Link>
											);
										}
									}
								})}
							</div>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	);
};
