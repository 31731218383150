import {groq} from 'next-sanity';
import type {Slug} from 'sanity';
import type {LinkableDocumentTypes} from 'utils';

export const resolvedMainNavigationQuery = groq`
*[_type == "siteSettings" && !(_id in path('drafts.**'))][0].mainNavigation[] {
	_type,
	_key,
	title,
	type,
	targetBlank,
	href,
	reference->{
		_type,
		slug,
		title,
	},
	dropdownLinks[] {
		_type,
		_key,
		title,
		type,
		targetBlank,
		href,
		reference->{
			_type,
			slug,
			title,
		},
	}
}`;

type Reference = {
	_type: LinkableDocumentTypes;
	slug: Slug | null;
	title: string | null;
};

type LinkBase = {
	_type: 'navigationLink' | 'dropdown';
	_key: string;
	title: string | null;
	type: 'internal' | 'external' | null;
	targetBlank: boolean | null;
	href: string | null;
	reference: Reference | null;
};

export type ResolvedMainNavigationQueryResult =
	| (LinkBase & {
			dropdownLinks: LinkBase[] | null;
	  })
	| null;
